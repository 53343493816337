import React, { useEffect, useState } from 'react';
import MortgageConfigurationService, { MortgageConfigData } from '../../services/mortgage/mortgage.service';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, TextField, Button } from '@material-ui/core';
import InternalNavBar from '../navigation/internal.navigation.component';
import { useStyles } from '../../styles/styles';
import { useNavigate } from 'react-router-dom';

export const MortgageConfiguration: React.FC = () => {
    const classes = useStyles();
    const [mortgageconfigdata, setMortgageConfigData] = useState<MortgageConfigData>({
        userMortgageConfigurationId: '',
        years: '',
        downPaymentPercent: '',
        fico: '',
        userId: '',
      });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [years, setYears] = useState('');
    const [downPaymentPercent, setDownPaymentPercent] = useState('');
    const [fico, setFico] = useState('');
    const [userMorgageConfigurationId, setUserMorgageConfigurationId] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError('')
        setSuccess(false)
        setLoading(true);
        try {
            await MortgageConfigurationService.save(mortgageconfigdata);
            setSuccess(true);
        } catch (error: unknown) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unknown error occurred during registration.');
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchMorgageConfiguration = async () => {
            try {
                const mortgageconfigdata = await MortgageConfigurationService.getMortgageConfiguration();
                setMortgageConfigData(mortgageconfigdata);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during mortgage:', error);
                } else {
                    setError('An unknown error occurred during mortgage.');
                    console.error('Error during mortgage:', error);
                }
            }
        };

        fetchMorgageConfiguration();
    }, []);

    if (!mortgageconfigdata) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.root}>
            <InternalNavBar />
            <div className={classes.header}>
                <Typography className={classes.title} variant="h1">
                    Mortgage Configuration
                </Typography>
            </div>
            <form onSubmit={handleSubmit}>
                <input type="hidden" name="userMortgageConfigurationId" value={mortgageconfigdata.userMortgageConfigurationId} />
                <Table className={classes.table} aria-label="User Information table">
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <TextField
                                    label="Years"
                                    value={mortgageconfigdata.years}
                                    onChange={(event) => setMortgageConfigData({
                                        ...mortgageconfigdata,
                                        years: event.target.value
                                    })}
                                    fullWidth
                                    margin="normal"
                                />
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TextField
                                    label="Down Payment Percentage"
                                    value={mortgageconfigdata.downPaymentPercent}
                                    onChange={(event) => setMortgageConfigData({
                                        ...mortgageconfigdata,
                                        downPaymentPercent: event.target.value
                                    })}
                                    fullWidth
                                    margin="normal"
                                />
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TextField
                                    label="Fico Score"
                                    value={mortgageconfigdata.fico}
                                    onChange={(event) => setMortgageConfigData({
                                        ...mortgageconfigdata,
                                        fico: event.target.value
                                    })
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
            </form>
            {loading && <p className="loading">Loading...</p>}
            {error && <p className="error">{error}</p>}
            {success && <p className="success">Mortgage Configuration Saved</p>}
        </div>
    );
};
export default MortgageConfiguration;