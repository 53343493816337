import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { Route, Routes, Link } from 'react-router-dom';
import PrivateRoute from '../privateroute/privateroute.component';
import Account from '../account/account.component';
import InternalNavBar from '../navigation/internal.navigation.component';
import { useStyles } from '../../styles/styles';
import { TextField, Button, Typography } from '@material-ui/core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';


const Home: React.FC = () => {
  const classes = useStyles();
  const [jwt, setJwt] = React.useState<string | null>(null);
  const [ogUserId, setOgUserId] = React.useState<string | null>(null);

  const decodeJwt = (rawJwt: string) => {
    try {
      const base64Url = rawJwt.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  };
  React.useEffect(() => {
    const fetchJwt = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const rawJwt = user.signInUserSession.idToken.jwtToken;
        setJwt(rawJwt);
        const decodedJwt: any = decodeJwt(rawJwt);
        if (decodedJwt) {
          setOgUserId(decodedJwt['custom:ogUserId']);
        }
      } catch (error) {
        console.error('Error fetching JWT:', error);
      }
    };

    fetchJwt();
  }, []);

  return (
    <div className={classes.root}>
      <InternalNavBar />
      <div className={classes.header}>
        <Typography className={classes.title} variant="h1">
          Welcome to the Home Page
        </Typography>
      </div>
      <p>You are now logged in.</p>
      <Table className={classes.table} aria-label="User Information table">
        <TableHead>
          <TableRow>
            <TableCell>JWT</TableCell>
            <TableCell align="right">User ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.cell}>
              {jwt}
            </TableCell>
            <TableCell align="right" className={classes.cell}>
              {ogUserId}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default Home;