// src/Account.tsx
import React, { useEffect, useState } from 'react';
import AccountService, { UserAccount } from '../../services/account/account.service';
import Home from '../home/home.component';
import { Route, Routes, Link } from 'react-router-dom';
import PrivateRoute from '../privateroute/privateroute.component';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import InternalNavBar from '../navigation/internal.navigation.component';
import { useStyles } from '../../styles/styles';
import { TextField, Button, Typography } from '@material-ui/core';
const Account: React.FC = () => {
    const classes = useStyles();
    const [userAccount, setUserAccount] = useState<UserAccount | null>(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUserAccount = async () => {
            try {
                const account = await AccountService.getUserAccount();
                setUserAccount(account);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during login:', error);
                } else {
                    setError('An unknown error occurred during login.');
                    console.error('Error during login:', error);
                }
            }
        };

        fetchUserAccount();
    }, []);

    if (!userAccount) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.root}>
            <InternalNavBar />
            <div className={classes.header}>
                <Typography className={classes.title} variant="h1">
                    User Account Information
                </Typography>
            </div>

            <Table className={classes.table} aria-label="User Information table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Active</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.cell}>
                            {userAccount.userId}
                        </TableCell>
                        <TableCell className={classes.cell}>
                            {userAccount.email}
                        </TableCell>
                        <TableCell className={classes.cell}>
                            {userAccount.active ? 'Yes' : 'No'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

export default Account;
