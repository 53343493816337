import axios from "axios";
import { AxiosError } from "axios";

const handleError = (error: any, errorMessage?: string): string => {
    if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(axiosError.response.data);
          console.log(axiosError.response.status);
          console.log(axiosError.response.headers);
          const data = axiosError.response.data as { error: string };
          return axiosError.response.status + " : " + data.error;
        } else if (axiosError.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in Node.js
          console.log(axiosError.request);
          return "No Response Received: " +axiosError.request.toString();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', axiosError.message);
          return axiosError.message.toString();
        }
        
      }else{
        // Handle non-axios errors
        console.error('An unknown error occurred:', error);
        return 'non axios error: ' + error.toString();
      }
  };
  
  export default handleError;