import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import { Login } from './components/login/login.component';
import Home from './components/home/home.component';
import PrivateRoute from './components/privateroute/privateroute.component';
import Account from './components/account/account.component';
import MortgageConfiguration from './components/mortgage/mortgage.component';
import InvestmentFilter from './components/investmentfilter/investmentfilter.component';
import SearchList from './components/search/searchlist.component';
import OpportunityList from './components/opportunity/opportunitylist.component';
import ScrollToTop from './config/scrolltotop';

import "./App.css";

import Register from "./components/register/register.component";
import SearchFilterEdit from './components/search/searchedit.component';

const App: React.FC = () => {

  return (
      <div>   
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Register />} />
          <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
          <Route path="/mortgage" element={<PrivateRoute><MortgageConfiguration /></PrivateRoute>} />
          <Route path="/investmentfilter" element={<PrivateRoute><InvestmentFilter /></PrivateRoute>} />
          <Route path="/searchlist" element={<PrivateRoute><SearchList /></PrivateRoute>} />
          <Route path="/searchedit/:userSearchId" element={<PrivateRoute><SearchFilterEdit /></PrivateRoute>} />
          <Route path="/searchedit/" element={<PrivateRoute><SearchFilterEdit /></PrivateRoute>} />
          <Route path="/opportunities" element={<PrivateRoute><OpportunityList /></PrivateRoute>} />
        </Routes>
      </div>
  );
};

export default App;