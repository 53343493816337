import React, { useEffect, useState } from 'react';
import InvestmentFilterService, { InvestmentFilterData } from '../../services/investmentfilter/investmentfilter.service';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, TextField, Button } from '@material-ui/core';
import InternalNavBar from '../navigation/internal.navigation.component';
import { useStyles } from '../../styles/styles';

export const InvestmentFilter: React.FC = () => {
    const classes = useStyles();
    const [investmentfilterdata, setInvestmentFilterData] = useState<InvestmentFilterData>({
        opportunityFilterConfigurationId: '',
        userId: '',
        cashflow: '',
        capitalizationRate: '',
        cashOnCashReturn: '',

    });
    const [error, setError] = useState('');

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError('')
        setSuccess(false)
        setLoading(true);
        try {
            await InvestmentFilterService.save(investmentfilterdata);
            setSuccess(true);
        } catch (error: unknown) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unknown error occurred during registration.');
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchMorgageConfiguration = async () => {
            try {
                const investmentfilterdata = await InvestmentFilterService.getInvestmentFilter();
                console.log("returndata", investmentfilterdata)
                setInvestmentFilterData(investmentfilterdata);
                console.log("searchdata", investmentfilterdata)
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during mortgage:', error);
                } else {
                    setError('An unknown error occurred during mortgage.');
                    console.error('Error during mortgage:', error);
                }
            }
        };

        fetchMorgageConfiguration();
    }, []);

    if (!investmentfilterdata) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.root}>
            <InternalNavBar />
            <div className={classes.header}>
                <Typography className={classes.title} variant="h1">
                    Investment Filter Configuration
                </Typography>
            </div>
            <form onSubmit={handleSubmit}>
                <input type="hidden" name="opportunityFilterConfigurationId" value={investmentfilterdata.opportunityFilterConfigurationId} />
                <Table className={classes.table} aria-label="User Information table">
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <TextField
                                    label="Cash Flow"
                                    value={investmentfilterdata.cashflow}
                                    onChange={(event) => setInvestmentFilterData({
                                        ...investmentfilterdata,
                                        cashflow: event.target.value
                                    })}
                                    fullWidth
                                    margin="normal"
                                />
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TextField
                                    label="Capitalization Rate"
                                    value={investmentfilterdata.capitalizationRate}
                                    onChange={(event) => setInvestmentFilterData({
                                        ...investmentfilterdata,
                                        capitalizationRate: event.target.value
                                    })}
                                    fullWidth
                                    margin="normal"
                                />
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TextField
                                    label="Cash on Cash Return"
                                    value={investmentfilterdata.cashOnCashReturn}
                                    onChange={(event) => setInvestmentFilterData({
                                        ...investmentfilterdata,
                                        cashOnCashReturn: event.target.value
                                    })
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
            </form>
            {loading && <p className="loading">Loading...</p>}
            {error && <p className="error">{error}</p>}
            {success && <p className="success">Investment Filter Configuration Saved</p>}
        </div>
    );
};
export default InvestmentFilter;