// src/Registration.tsx
import React, { useState } from 'react';
import RegisterService from '../../services/register/register.service';
import { TextField, Button, Link, Typography } from '@material-ui/core';
import { useStyles } from '../../styles/styles';
import NavBar from '../navigation/navigation.component';

const RegistrationPage: React.FC = () => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('')
    setSuccess(false)
    setLoading(true);
    try {
      await RegisterService.register(email, username);
      setSuccess(true);
      setEmail('');
      setUsername('');
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred during registration.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <NavBar />
      <div className={classes.header}>
      <Typography className={classes.title} variant="h1">
        Registration
      </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <TextField
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>
        <div>
          <label htmlFor="username">Username:</label>
          <TextField
            type="username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
        </div>
        <Button type="submit" variant="contained" color="primary">
          Register
        </Button>
      </form>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">Registration successful! Check your email for a password to login with and then proceed to the login page.</p>}
    </div>
  );
};

export default RegistrationPage;
