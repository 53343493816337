import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        fontSize: '2rem',
        fontWeight: 500,
        letterSpacing: '0.05em',
        color: '#fff',
    },
    header: {
        backgroundColor: '#3f51b5',
        padding: '1rem',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    table: {
        maxWidth: 500,
        margin: '0 auto'
    },
    cell: {
        maxWidth: 200,
        wordWrap: 'break-word'
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    navLink: {
        color: 'white',
        marginRight: '1rem',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

