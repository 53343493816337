import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Link, Typography } from '@material-ui/core';
import { useStyles } from '../../styles/styles';
import NavBar from '../navigation/navigation.component';


export const Login: React.FC = () => {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const user = await Auth.signIn(username, password);
      console.log('User logged in:', user);
      // Redirect to another page after successful login
      navigate('/home');
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        console.error('Error during login:', error);
      } else {
        setError('An unknown error occurred during login.');
        console.error('Error during login:', error);
      }
    }
  };

  return (
    <div className={classes.root}>
      <NavBar />
      <div className={classes.header}>
        <Typography className={classes.title} variant="h1">
          Login
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        {error && <p className="error">{error}</p>}
        <TextField
          label="Username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>

        <Typography variant="body2">
          {"Don't have an account? "}
          <Link href="/registration" variant="body2">
            Sign Up
          </Link>
        </Typography>
      </form>
    </div>
  );
};      