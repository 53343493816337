import axios from "axios";
import { AxiosError } from "axios";
import { Auth } from '@aws-amplify/auth';
import handleError from "../errorhandling";

const API_URL = process.env.REACT_APP_API_HOST || 'https://api.opportunitygopher.com';
export interface InvestmentFilterData {
  opportunityFilterConfigurationId: string;
  userId: string;
  cashflow: string;
  capitalizationRate: string
  cashOnCashReturn: string
}

class InvestmentFilterService {
  static async getInvestmentFilter(): Promise<InvestmentFilterData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      const response = await axios.get<InvestmentFilterData>(API_URL + '/api/protected/opportunity-filter',
        config
      );

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error getting investment filter');
      throw new Error(errorMessage);

    }
  }

  static async save(investmentFilterData: InvestmentFilterData): Promise<InvestmentFilterData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };

      const response = await axios.post(API_URL + '/api/protected/opportunity-filter', investmentFilterData, config);

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error saving investment filter');
      throw new Error(errorMessage);
    }

  }
}


export default InvestmentFilterService;