import React, { useEffect, useState } from 'react';
import SearchService, { SearchFilterData } from '../../services/search/search.service';
import {
    Typography, TextField, Button, Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';
import InternalNavBar from '../navigation/internal.navigation.component';
import { useStyles } from '../../styles/styles';
import { useNavigate, useParams } from 'react-router-dom';

export const SearchFilterEdit: React.FC = () => {
    const classes = useStyles();
    const { userSearchId } = useParams();
    type PropertyType = 'SINGLE_FAMILY' | 'APARTMENT' | 'CONDO' | 'TOWNHOME' | 'MULTI_FAMILY';

    const [searchData, setSearchData] = useState<SearchFilterData>({
        userSearchId: '',
        county: '',
        state: '',
        minimumPrice: '',
        maximumPrice: '',
        maximumHoa: '',
        minimumBedrooms: '',
        minimumBathrooms: '',
        propertyTypes: [],
        userId: '',
        searchUrl: '',
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleStringFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: keyof Pick<SearchFilterData,
            | "county"
            | "state"
            | "minimumPrice"
            | "maximumPrice"
            | "maximumHoa"
            | "minimumBedrooms"
            | "minimumBathrooms">
    ) => {
        const updatedSearchData = { ...searchData };
        updatedSearchData[field] = event.target.value.trim().toUpperCase();
        setSearchData(updatedSearchData);
    };

    const handlePropertyTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        type: PropertyType
    ) => {
        const updatedSearchData = { ...searchData };
        const typeIndex = updatedSearchData.propertyTypes.indexOf(type);
        if (typeIndex === -1) {
            updatedSearchData.propertyTypes.push(type);
        } else {
            updatedSearchData.propertyTypes.splice(typeIndex, 1);
        }
        setSearchData(updatedSearchData);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setSuccess(false);
        if (searchData.userSearchId === '') {
            try {
                await SearchService.create(searchData);
                setLoading(false);
                setSuccess(true);
                navigate('/searchlist'); // navigate to list page on successful submit
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during update:', error);
                } else {
                    setError('An unknown error occurred during update.');
                    console.error('Error during update:', error);
                }
                setLoading(false);
                
            }
        } else {
            try {
                await SearchService.update(searchData);
                setLoading(false);
                setSuccess(true);
                navigate('/searchlist'); // navigate to list page on successful submit
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during update:', error);
                } else {
                    setError('An unknown error occurred during update.');
                    console.error('Error during update:', error);
                }
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        const fetchSearchFilterData = async () => {
            if (!userSearchId) {
                return;
            }
            try {
                const searchData = await SearchService.getSearch(userSearchId);
                setSearchData(searchData);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during Search:', error);
                } else {
                    setError('An unknown error occurred during search.');
                    console.error('Error during search:', error);
                }
            }
        };

        fetchSearchFilterData();
    }, [userSearchId]);

    if (!searchData) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.root}>
            <InternalNavBar />
            <div className={classes.header}>
                <Typography className={classes.title} variant="h1">
                    Search Configuration
                </Typography>
            </div>
            <div>
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="userMortgageConfigurationId" value={searchData.userSearchId} />
                    <p>
                        <TextField name="county" value={searchData.county}
                            onChange={(event) =>
                                handleStringFieldChange(
                                    event,
                                    "county"
                                )
                            }
                            label="County"
                            fullWidth margin="normal" />
                    </p>
                    <p>
                        <TextField name="state" value={searchData.state}
                            onChange={(event) =>
                                handleStringFieldChange(
                                    event,
                                    "state"
                                )
                            }
                            label="State"
                            fullWidth margin="normal" />
                    </p>
                    <p>
                        <TextField name="minimumPrice" value={searchData.minimumPrice}
                            onChange={(event) =>
                                handleStringFieldChange(
                                    event,
                                    "minimumPrice"
                                )
                            }
                            label="Minimum Price"
                            fullWidth margin="normal" />
                    </p>
                    <p>
                        <TextField name="maximumPrice" value={searchData.maximumPrice}
                            onChange={(event) =>
                                handleStringFieldChange(
                                    event,
                                    "maximumPrice"
                                )
                            }
                            label="Maximum Price"
                            fullWidth margin="normal" />
                    </p>
                    <p>
                        <TextField name="maximumHoa" value={searchData.maximumHoa}
                            onChange={(event) =>
                                handleStringFieldChange(
                                    event,
                                    "maximumHoa"
                                )
                            }
                            label="Maximum HOA"
                            fullWidth margin="normal" />
                    </p>
                    <p>
                        <TextField name="minimumBedrooms" value={searchData.minimumBedrooms}
                            onChange={(event) =>
                                handleStringFieldChange(
                                    event,
                                    "minimumBedrooms"
                                )
                            }
                            label="Minimum Bedrooms"
                            fullWidth margin="normal" />
                    </p>
                    <p>
                        <TextField name="minimumBathrooms" value={searchData.minimumBathrooms}
                            onChange={(event) =>
                                handleStringFieldChange(
                                    event,
                                    "minimumBathrooms"
                                )
                            }
                            label="Minimum Bathrooms"
                            fullWidth margin="normal" />
                    </p>
                    <p className={classes.cell}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={searchData.propertyTypes.includes('SINGLE_FAMILY')}
                                    value="SINGLE_FAMILY"
                                    onChange={(event) => handlePropertyTypeChange(event, 'SINGLE_FAMILY')}
                                />}
                                label="Single Family"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={searchData.propertyTypes.includes('APARTMENT')}
                                    value="APARTMENT"
                                    onChange={(event) => handlePropertyTypeChange(event, 'APARTMENT')}
                                />}
                                label="Apartment"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={searchData.propertyTypes.includes('CONDO')}
                                    value="CONDO"
                                    onChange={(event) => handlePropertyTypeChange(event, 'CONDO')}
                                />}
                                label="Condo"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={searchData.propertyTypes.includes('TOWNHOME')}
                                    value="TOWNHOME"
                                    onChange={(event) => handlePropertyTypeChange(event, 'TOWNHOME')}
                                />}
                                label="Townhome"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={searchData.propertyTypes.includes('MULTI_FAMILY')}
                                    value="MULTI_FAMILY"
                                    onChange={(event) => handlePropertyTypeChange(event, 'MULTI_FAMILY')}
                                />}
                                label="Multi Family"
                            />

                        </FormGroup>
                    </p>
                    <p>
                        <Button type="submit" variant="contained"
                            color="primary">
                            Save
                        </Button>
                    </p>
                </form>
                {loading && <p className="loading">Loading...</p>}
                {error && <p className="error">{error}</p>}
                {success && <p className="success">Search Filter Configuration Saved</p>}
            </div >
        </div>
    );
};
export default SearchFilterEdit;