import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    AppBar, Toolbar,
    Menu,
    MenuItem,
    IconButton,
    Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useStyles } from '../../styles/styles';
import { Auth } from '@aws-amplify/auth';


const InternalNavBar = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            localStorage.removeItem('jwt');
            console.log('JWT removed from storage');
            navigate('/');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Opportunity Gopher
                </Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleOpenMenu}
                    style={{ [classes.navLink]: true }}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={handleCloseMenu}>
                        <Link to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Home
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                        <Link
                            to="/account"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Account
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                        <Link
                            to="/mortgage"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Mortgage Configuration
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                        <Link
                            to="/investmentfilter"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Filter Configuration
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                        <Link to="/searchlist" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Search
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                        <Link
                            to="/opportunities"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Opportunities
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default InternalNavBar;