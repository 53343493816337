import React, { useEffect, useState } from 'react';
import OpportunityService, { OpportunityData } from '../../services/opportunity/opportunity.service'
import {
    Table, TableHead, TableRow, TableCell, TableBody,
    TableContainer, Typography, TextField, Button,
    Paper, Chip
} from '@material-ui/core';
import InternalNavBar from '../navigation/internal.navigation.component';
import { useStyles } from '../../styles/styles';
import { useNavigate, Link } from 'react-router-dom';

export const OpportunityList: React.FC = () => {
    const classes = useStyles();
    const [opportunityList, setOpportunityList] = useState<OpportunityData[]>([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    const pctFormat = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
    });
    const dtFormat = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short'
    })
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                const opportunityList = await OpportunityService.getOpportunityList();
                setOpportunityList(opportunityList);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during opportunity:', error);
                } else {
                    setError('An unknown error occurred during opportunity.');
                    console.error('Error during opportunity:', error);
                }
            }
        };

        fetchOpportunities();
    }, []);

    if (!opportunityList) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.root}>
            <InternalNavBar />
            <div className={classes.header}>
                <Typography className={classes.title} variant="h1">
                    Opportunity List
                </Typography>
            </div>
            <p>Results: {opportunityList.length}</p>
            <TableContainer component={Paper}>
                <Table aria-label="Opportunities">
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Capitalization Rate</TableCell>
                            <TableCell>Cash on Cash</TableCell>
                            <TableCell>Total Cost</TableCell>
                            <TableCell>Rental Estimate</TableCell>
                            <TableCell>Cash Flow</TableCell>
                            <TableCell>Date Created</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {opportunityList.map((opportunity, index) => (
                            <TableRow key={opportunity.opportunityId}>
                                <TableCell>
                                    <Link to={opportunity.listingDetail.listingUrl} target="_blank">
                                        {opportunity.listingDetail.addressLine1} {opportunity.listingDetail.city}, {opportunity.listingDetail.state} {opportunity.listingDetail.zipCode}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                        {formatter.format(opportunity.listingDetail.price)}
                                </TableCell>
                                <TableCell>
                                        {pctFormat.format(opportunity.investmentMetrics.capitalizationRate/100)}
                                </TableCell>
                                <TableCell>
                                        {pctFormat.format(opportunity.investmentMetrics.cashOnCashReturn/100)}
                                </TableCell>
                                <TableCell>
                                        {formatter.format(opportunity.investmentMetrics.monthlyCosts)}
                                </TableCell>
                                <TableCell>
                                        {formatter.format(opportunity.listingDetail.rentalEstimate)}
                                </TableCell>
                                <TableCell>
                                        {formatter.format(opportunity.investmentMetrics.cashFlow)}
                                </TableCell>
                                <TableCell>
                                        {null!=opportunity.createdInstant ? dtFormat.format(opportunity.createdInstant) : ''}
                                </TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {loading && <p className="loading">Loading...</p>}
            {error && <p className="error">{error}</p>}
        </div >
    );
};
export default OpportunityList;