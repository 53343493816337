import axios from "axios";
import { AxiosError } from "axios";
import { Auth } from '@aws-amplify/auth';
import handleError from "../errorhandling";

const API_URL = process.env.REACT_APP_API_HOST || 'https://api.opportunitygopher.com';
export interface UserAccount {
  // Add the fields from the response of the user.opportunitygopher.com/user-account endpoint
  // For example:
  userId: string;
  email: string;
  active: boolean
}

class AccountService {
  static async getUserAccount(): Promise<UserAccount> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };

      const response = await axios.get<UserAccount>(API_URL + '/api/protected/user-account',
        config,
      );

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error getting user account');
      throw new Error(errorMessage);
    }
  }
}

export default AccountService;