import axios from "axios";
import { AxiosError } from "axios";
import { Auth } from '@aws-amplify/auth';
import handleError from "../errorhandling";

const API_URL = 'https://opportunity.opportunitygopher.com';
export interface OpportunityData {
  opportunityId: string;
  listingDetail: ListingDetail;
  hasBeenProcessed: boolean;
  investmentMetrics: InvestmentMetrics;
  hasMetrics: boolean;
  hasSentNotification: boolean;
  match: boolean;

  createdInstant: Date;
}
export interface ListingDetail {
  listingId: string;
  listingUrl: string;
  price: number;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  sqFt: number;
  numberOfBedrooms: number;
  numberOfBathrooms: number;
  propertyType: string;
  monthlyHoa: number;
  monthlyTaxes: number;
  monthlyInsurance: number;
  rentalEstimate: number;
  latitude: number;
  longitude: number;

}

export interface InvestmentMetrics {
  downPayment: number;
  pricePerSqFt: number;
  cashFlow: number;
  capitalizationRate: number;
  cashOnCashReturn: number;
  mortgageRate: number;
  principalAndInterest: number;
  monthlyCosts: number;
}

class OpportunityService {

  static async getOpportunityList(): Promise<OpportunityData[]> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      const response = await axios.get<OpportunityData[]>(API_URL + '/api/protected/opportunities', config);

      console.log(response.data);
      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error getting opportunity list');
      throw new Error(errorMessage);

    }
  }

}

export default OpportunityService;