import React,  {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar, Toolbar,
    Menu,
    MenuItem,
    IconButton,
    Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useStyles } from '../../styles/styles';

const NavBar = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Opportunity Gopher
                </Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleOpenMenu}
                    style={{ [classes.navLink]: true }}
                >
                    <MenuIcon />
                </IconButton>
                                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={handleCloseMenu}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Login
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseMenu}>
                        <Link to="registration" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Register
                        </Link>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;