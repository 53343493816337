import axios from "axios";
import { AxiosError } from "axios";
import { Auth } from '@aws-amplify/auth';
import handleError from "../errorhandling";

const API_URL = process.env.REACT_APP_API_HOST || 'https://api.opportunitygopher.com';
export interface SearchFilterData {
  userSearchId: string;
  userId: string;
  searchUrl: string;
  county: string;
  state: string;
  minimumPrice: string;
  maximumPrice: string;
  maximumHoa: string;
  propertyTypes: string[];
  minimumBedrooms: string;
  minimumBathrooms: string;
}
const decodeJwt = (rawJwt: string) => {
  try {
    const base64Url = rawJwt.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
};
class SearchService {

  static async getSearchList(): Promise<SearchFilterData[]> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      const response = await axios.get<SearchFilterData[]>(API_URL + '/api/protected/user-search', config);

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error getting search criteria list');
      throw new Error(errorMessage);

    }
  }
  static async getSearch(userSearchId: string | undefined): Promise<SearchFilterData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      const response = await axios.get<SearchFilterData>(API_URL + '/api/protected/user-search/' + userSearchId, config);

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error getting a search criteria');
      throw new Error(errorMessage);
    }
  }

  static async update(searchData: SearchFilterData): Promise<SearchFilterData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };

      const response = await axios.put(API_URL + '/api/protected/user-search/' + searchData.userSearchId, searchData, config);

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error updating search criteria');
      throw new Error(errorMessage);

    }
  }
  static async create(searchData: SearchFilterData): Promise<SearchFilterData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      const decodedJwt: any = decodeJwt(jwt);
      const ogUserId = decodedJwt['custom:ogUserId'];
      searchData.userId = ogUserId;
      const response = await axios.post(API_URL + '/api/protected/user-search', searchData, config);

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error creating a search criteria');
      throw new Error(errorMessage);
    }
  }
}

export default SearchService;