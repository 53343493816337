import axios from "axios";
import { AxiosError } from "axios";
import handleError from "../errorhandling";

console.log('API_URL:', process.env.REACT_APP_API_HOST);
const API_URL = process.env.REACT_APP_API_HOST || 'https://user.opportunitygopher.com';
const RegisterService = {
  async register(email: string, username: string): Promise<void> {
    try {
      await axios.post(API_URL+'/public/user-account', {
        email,
        username,
      });
    } catch (error : unknown) {
      const errorMessage = handleError(error, 'Error registering user');
      throw new Error(errorMessage);
      
    }
  },
};

export default RegisterService;