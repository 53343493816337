import React, { useEffect, useState } from 'react';
import SearchService, { SearchFilterData } from '../../services/search/search.service';
import {
    Table, TableHead, TableRow, TableCell, TableBody,
    TableContainer, Typography, TextField, Button,
    Paper, Chip
} from '@material-ui/core';
import InternalNavBar from '../navigation/internal.navigation.component';
import { useStyles } from '../../styles/styles';
import { useNavigate, Link } from 'react-router-dom';

export const SearchList: React.FC = () => {
    const classes = useStyles();
    const [searchDataList, setSearchDataList] = useState<SearchFilterData[]>([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchMorgageConfiguration = async () => {
            try {
                const searchDataList = await SearchService.getSearchList();
                console.log("returndata", searchDataList)
                setSearchDataList(searchDataList);
                console.log("searchdata", searchDataList)
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                    console.error('Error during mortgage:', error);
                } else {
                    setError('An unknown error occurred during mortgage.');
                    console.error('Error during mortgage:', error);
                }
            }
        };

        fetchMorgageConfiguration();
    }, []);

    if (!searchDataList) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.root}>
            <InternalNavBar />
            <div className={classes.header}>
                <Typography className={classes.title} variant="h1">
                    Search Configuration List
                </Typography>
            </div>
            <p>{searchDataList.length}</p>
            <TableContainer component={Paper}>
                <Table aria-label="Search Configuration Table">
                    <TableHead>
                        <TableRow>
                            <TableCell>County</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Minimum Price</TableCell>
                            <TableCell>Maximum Price</TableCell>
                            <TableCell>Maximum HOA</TableCell>
                            <TableCell>Minimum Bedrooms</TableCell>
                            <TableCell>Minimum Bathrooms</TableCell>
                            <TableCell>Property Type</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchDataList.map((searchData, index) => (
                            <TableRow key={searchData.userSearchId}>
                                <TableCell>
                                    {searchData.county}
                                </TableCell>
                                <TableCell>
                                    {searchData.state}
                                </TableCell>
                                <TableCell>
                                    {searchData.minimumPrice}
                                </TableCell>
                                <TableCell>
                                    {searchData.maximumPrice}
                                </TableCell>
                                <TableCell>
                                    {searchData.maximumHoa}
                                </TableCell>
                                <TableCell>
                                    {searchData.minimumBedrooms}
                                </TableCell>
                                <TableCell>
                                    {searchData.minimumBathrooms}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {searchData.propertyTypes.map((type) => (
                                        <Chip key={type} label={type} className={classes.chip} />
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <Link to={`/searchedit/${searchData.userSearchId}`}>Edit</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>
                                <Link to={`/searchedit/`}>Add</Link>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {loading && <p className="loading">Loading...</p>}
            {error && <p className="error">{error}</p>}
            {success && <p className="success">Investment Filter Configuration Saved</p>}
        </div >
    );
};
export default SearchList;