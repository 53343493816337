import axios from "axios";
import { AxiosError } from "axios";
import { Auth } from '@aws-amplify/auth';
import handleError from "../errorhandling";

console.log('API_URL:', process.env.REACT_APP_API_HOST);
const API_URL = process.env.REACT_APP_API_HOST || 'https://api.opportunitygopher.com';

export interface MortgageConfigData {
  // Add the fields from the response of the user.opportunitygopher.com/user-account endpoint
  // For example:
  userId: string;
  years: string;
  downPaymentPercent: string
  fico: string
  userMortgageConfigurationId: string
}

class MortgageConfigurationService {
  static async getMortgageConfiguration(): Promise<MortgageConfigData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };
      const response = await axios.get<MortgageConfigData>(API_URL + '/api/protected/mortgage-configuration', config);

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error getting mortgage configuration');
      throw new Error(errorMessage);

    }
  }

  static async save(mortgageConfigData: MortgageConfigData): Promise<MortgageConfigData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwt = user.signInUserSession.idToken.jwtToken;
      const config = {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      };

      const response = await axios.post(API_URL + '/api/protected/mortgage-configuration', mortgageConfigData, config);

      return response.data;
    } catch (error) {
      const errorMessage = handleError(error, 'Error saving mortgage configuration');
      throw new Error(errorMessage);

    }
  }
}

export default MortgageConfigurationService;